import { Expose, plainToInstance, Type } from "class-transformer";
import {
  IsDate,
  IsEnum,
  IsNotEmpty,
  IsObject,
  IsOptional,
  IsString,
  validateSync,
} from "class-validator";

export enum SiteWalkSource {
  CALENDAR = "CALENDAR",
  PLATFORM_MESSAGE = "PLATFORM_MESSAGE",
}

export enum SiteWalkStatus {
  SCHEDULED = "SCHEDULED",
  RESCHEDULED = "RESCHEDULED",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
}

export class SiteWalkPublic {
  public static from(siteWalk: SiteWalkPublic): SiteWalkPublic {
    const instance = plainToInstance(SiteWalkPublic, siteWalk, {
      excludeExtraneousValues: true,
      enableCircularCheck: true,
    });

    const errors = validateSync(instance);
    if (errors.length > 0) {
      throw new Error(
        `Validation failed: ${errors.map((error) => Object.values(error.constraints || {})).join(", ")}`,
      );
    }

    return instance;
  }

  @Expose()
  @IsString()
  @IsNotEmpty()
  id: string;

  @Expose()
  @IsEnum(SiteWalkStatus)
  @IsNotEmpty()
  status: SiteWalkStatus;

  @Expose()
  @IsString()
  @IsNotEmpty()
  contractorId: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  clientName: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  contractorName: string;

  @Expose()
  @IsString()
  @IsOptional()
  contractorLogoUrl?: string;

  @Expose()
  @IsString()
  @IsOptional()
  contractorHeadshotUrl?: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  clientAddress: string;

  @Expose()
  @IsDate()
  @IsNotEmpty()
  @Type(() => Date)
  scheduledForDateStart: Date;

  @Expose()
  @IsDate()
  @IsNotEmpty()
  @Type(() => Date)
  scheduledForDateEnd: Date;

  @Expose()
  @IsString()
  @IsOptional()
  proFacingNotes?: string;

  @Expose()
  @IsObject()
  @IsOptional()
  coordinates?: { lng: number; lat: number };
}

export class SiteWalk extends SiteWalkPublic {
  public static override from(siteWalk: SiteWalk): SiteWalk {
    const instance = plainToInstance(SiteWalk, siteWalk, {
      excludeExtraneousValues: true,
      enableCircularCheck: true,
    });

    const errors = validateSync(instance);
    if (errors.length > 0) {
      throw new Error(
        `Validation failed: ${errors.map((error) => Object.values(error.constraints || {})).join(", ")}`,
      );
    }

    return instance;
  }

  @Expose()
  @IsOptional()
  @IsString()
  @IsNotEmpty()
  gcalEventId?: string;

  @Expose()
  @IsOptional()
  @IsString()
  reasonCancelled?: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  groupKey: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  buildProjectId: string;

  @Expose()
  @IsEnum(SiteWalkSource)
  @IsNotEmpty()
  source: SiteWalkSource;

  @Expose()
  @IsDate()
  @IsNotEmpty()
  @Type(() => Date)
  createdTs: Date;

  @Expose()
  @IsDate()
  @IsNotEmpty()
  @Type(() => Date)
  updatedTs: Date;
}
