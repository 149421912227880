"use client";

import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as RadioGroup from "@radix-ui/react-radio-group";
import { differenceInCalendarDays, format } from "date-fns";
import * as React from "react";
import { DayPicker, useDayPicker, type DayPickerProps } from "react-day-picker";
import { cn } from "../utils";
import { Button } from "./Button";
import { Spinner } from "./Spinner";
import { ToggleCardInput } from "./ToggleCardInput";
import { Small } from "./typography/Small";

export type AvailabilitySlots = Record<
  /** Date string in "yyyy-mm-dd" format */
  string,
  {
    /** Start time of the availability slot */
    startTime: Date;
    /** End time of the availability slot */
    endTime: Date;
  }[]
>;

type CalendarProps = DayPickerProps & {
  /**
   * In the year view, the number of years to display at once.
   * @default 12
   */
  yearRange?: number;
  /**
   * Whether to show the year switcher in the caption.
   * @default true
   */
  showYearSwitcher?: boolean;
  monthsClassName?: string;
  monthCaptionClassName?: string;
  weekdaysClassName?: string;
  weekdayClassName?: string;
  monthClassName?: string;
  captionClassName?: string;
  captionLabelClassName?: string;
  buttonNextClassName?: string;
  buttonPreviousClassName?: string;
  navClassName?: string;
  monthGridClassName?: string;
  weekClassName?: string;
  dayClassName?: string;
  dayButtonClassName?: string;
  rangeStartClassName?: string;
  rangeEndClassName?: string;
  selectedClassName?: string;
  todayClassName?: string;
  outsideClassName?: string;
  disabledClassName?: string;
  rangeMiddleClassName?: string;
  hiddenClassName?: string;
  showAvailability?: boolean;
  availabilitySlots?: AvailabilitySlots;
  hasAvailabilityClassName?: string;
  noAvailabilityClassName?: string;
  modifiers?: Record<string, boolean>;
  modifiersClassNames?: Record<string, string>;
  onDayClick?: (
    day: Date,
    modifiers: Record<string, boolean>,
    e: React.MouseEvent,
  ) => void;
  onTimeSelect?: (startTime: Date, endTime: Date) => void;
  processingTimeSelection?: boolean;
};

function Calendar({
  className,
  showOutsideDays = true,
  showYearSwitcher = true,
  yearRange = 12,
  numberOfMonths,
  showAvailability = false,
  availabilitySlots,
  onTimeSelect,
  processingTimeSelection = false,
  ...props
}: CalendarProps) {
  const [navView, setNavView] = React.useState<"days" | "years">("days");
  const [displayYears, setDisplayYears] = React.useState<{
    from: number;
    to: number;
  }>(
    React.useMemo(() => {
      const currentYear = new Date().getFullYear();
      return {
        from: currentYear - Math.floor(yearRange / 2 - 1),
        to: currentYear + Math.ceil(yearRange / 2),
      };
    }, [yearRange]),
  );
  const [selectedDate, setSelectedDate] = React.useState<Date | undefined>(
    undefined,
  );
  const [selectedStartTime, setSelectedStartTime] = React.useState<
    Date | undefined
  >(undefined);
  const [selectedEndTime, setSelectedEndTime] = React.useState<
    Date | undefined
  >(undefined);
  const { onNextClick, onPrevClick, startMonth, endMonth } = props;
  const columnsDisplayed = navView === "years" ? 1 : numberOfMonths;

  // Class names with tailwind defaults
  const _monthsClassName = cn("relative flex", props.monthsClassName);
  const _monthCaptionClassName = cn(
    "relative mx-10 flex h-7 items-center justify-center pointer-events-none",
    props.monthCaptionClassName,
  );
  const _weekdaysClassName = cn("flex flex-row", props.weekdaysClassName);
  const _weekdayClassName = cn(
    "w-10 text-sm font-normal text-muted-foreground",
    props.weekdayClassName,
  );
  const _monthClassName = cn("w-full", props.monthClassName);
  const _captionClassName = cn(
    "relative flex items-center justify-center pt-1",
    props.captionClassName,
  );
  const _captionLabelClassName = cn(
    "truncate text-sm font-medium",
    props.captionLabelClassName,
  );
  const buttonNavClassName = cn(
    "absolute h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100",
  );
  const _buttonNextClassName = cn(
    buttonNavClassName,
    "right-0",
    props.buttonNextClassName,
  );
  const _buttonPreviousClassName = cn(
    buttonNavClassName,
    "left-0",
    props.buttonPreviousClassName,
  );
  const _navClassName = cn("flex items-start", props.navClassName);
  const _monthGridClassName = cn("mx-auto mt-4", props.monthGridClassName);
  const _weekClassName = cn("mt-2 flex w-max items-start", props.weekClassName);
  const _dayClassName = cn(
    "flex w-10 h-8 flex-1 items-center justify-center p-0 text-sm",
    props.dayClassName,
  );
  const _dayButtonClassName = cn(
    "size-8 rounded-full p-0 font-normal hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
    props.dayButtonClassName,
  );

  const buttonRangeClassName =
    "bg-accent [&>button]:bg-primary [&>button]:text-primary-foreground [&>button]:hover:bg-primary [&>button]:hover:text-primary-foreground";

  const _rangeStartClassName = cn(
    buttonRangeClassName,
    "day-range-start rounded-s-md",
    props.rangeStartClassName,
  );
  const _rangeEndClassName = cn(
    buttonRangeClassName,
    "day-range-end rounded-e-md",
    props.rangeEndClassName,
  );
  const _rangeMiddleClassName = cn(
    "bg-accent !text-foreground [&>button]:bg-transparent [&>button]:!text-foreground [&>button]:hover:bg-transparent [&>button]:hover:!text-foreground",
    props.rangeMiddleClassName,
  );
  const _selectedClassName = cn(
    "[&>button]:!bg-brand-primary-dark [&>button]:!text-white [&>button]:!font-semibold [&>button]:hover:!bg-brand-primary-dark",
    props.selectedClassName,
  );
  const _todayClassName = cn(
    "[&>button]:bg-primary/10 [&>button]:border-2 [&>button]:border-brand-primary-dark [&>button]:font-semibold",
    props.todayClassName,
  );
  const _outsideClassName = cn(
    "text-muted-foreground opacity-50",
    props.outsideClassName,
  );
  const _disabledClassName = cn(
    "text-muted-foreground opacity-50",
    props.disabledClassName,
  );
  const _hiddenClassName = cn("invisible flex-1", props.hiddenClassName);

  const _hasAvailabilityClassName = cn(
    "[&>button]:bg-brand-greens-light [&>button]:font-medium",
    props.hasAvailabilityClassName,
  );

  const _noAvailabilityClassName = cn(
    "[&>button]:font-medium",
    props.noAvailabilityClassName,
  );

  const modifiers = React.useMemo(() => {
    if (!showAvailability || !availabilitySlots) return props.modifiers;

    return {
      ...props.modifiers,
      hasAvailability: (date: Date) => {
        // Don't show availability for past dates
        if (date < new Date(new Date().setHours(0, 0, 0, 0))) {
          return false;
        }

        const dateStr = date.toISOString().split("T")[0]; // Convert to YYYY-MM-DD
        return dateStr in availabilitySlots;
      },
      noAvailability: (date: Date) => {
        // Don't show unavailability styling for past dates
        if (date < new Date(new Date().setHours(0, 0, 0, 0))) {
          return false;
        }

        const dateStr = date.toISOString().split("T")[0]; // Convert to YYYY-MM-DD
        return !(dateStr in availabilitySlots);
      },
      disabled: (date: Date): boolean => {
        //This now only disables days in the past
        //This previously disabled all days in the past as well as days with no availability
        const today = new Date(new Date().setHours(0, 0, 0, 0));
        if (date < today) {
          return true;
        }
        return false;
      },
    };
  }, [showAvailability, availabilitySlots, props.modifiers]);

  const [showTimePicker, setShowTimePicker] = React.useState(false);

  const handleDayClick = (
    day: Date,
    modifiers: Record<string, boolean>,
    e: React.MouseEvent,
  ) => {
    // Don't do anything if clicking the already selected date
    // This is to prevent weird behavior when showing times, todo: assess
    if (modifiers.selected) {
      return;
    }
    if (!modifiers.hasAvailability) {
      setSelectedDate(day);
    }

    if (modifiers.hasAvailability) {
      setShowTimePicker(true);
      setSelectedDate(day);
    }
    props.onDayClick?.(day, modifiers, e);
  };

  interface TimePickerProps {
    selectedDate: Date | undefined;
    className?: string;
  }

  function TimePicker({ selectedDate, className }: TimePickerProps) {
    const dateStr = selectedDate?.toISOString()?.split("T")?.[0];
    if (!dateStr) return null;

    const slots = availabilitySlots?.[dateStr] || [];
    return (
      <div
        className={cn(
          "flex w-full flex-col items-center space-y-2 p-4 md:w-44",
          className,
        )}
      >
        <Small className="text-brand-primary-regular text-center">
          {format(selectedDate, "EEEE, MMMM do")}
        </Small>
        {processingTimeSelection ? (
          <Spinner />
        ) : slots.length === 0 ? (
          <div className="pt-4 text-center">
            No availability for this date. Please pick another date.
          </div>
        ) : (
          <RadioGroup.Root
            value={selectedStartTime?.toISOString()}
            className="fon flex flex-row flex-wrap items-center justify-center gap-2 md:flex-col"
            onValueChange={(value) => {
              const selectedSlot = slots.find(
                (slot) => slot.startTime.toISOString() === value,
              );
              if (selectedSlot) {
                setSelectedStartTime(selectedSlot.startTime);
                setSelectedEndTime(selectedSlot.endTime);
              }
            }}
          >
            {slots.map((slot) => (
              <ToggleCardInput
                key={slot.startTime.toISOString()}
                value={slot.startTime.toISOString()}
                responseType="single-choice"
                timePicker={true}
                contentClassName={cn(
                  "text-xs whitespace-nowrap",
                  slot.startTime.toISOString() ===
                    selectedStartTime?.toISOString() && "font-bold",
                )}
              >
                {format(slot.startTime, "h:mm a")
                  .replace("AM", "am")
                  .replace("PM", "pm")}{" "}
                -{" "}
                {format(slot.endTime, "h:mm a")
                  .replace("AM", "am")
                  .replace("PM", "pm")}
              </ToggleCardInput>
            ))}
          </RadioGroup.Root>
        )}
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center gap-4">
      <div className="flex flex-col items-center gap-2 md:flex-row md:items-start">
        <DayPicker
          showOutsideDays={showOutsideDays}
          className={cn("bg-brand-beige-lighter p-3", className)}
          style={{
            width: 350.0 * (columnsDisplayed ?? 1) + "px",
          }}
          modifiers={modifiers}
          modifiersClassNames={{
            ...props.modifiersClassNames,
            hasAvailability: _hasAvailabilityClassName,
            noAvailability: _noAvailabilityClassName,
          }}
          classNames={{
            months: _monthsClassName,
            month_caption: _monthCaptionClassName,
            weekdays: _weekdaysClassName,
            weekday: _weekdayClassName,
            month: _monthClassName,
            caption: _captionClassName,
            caption_label: _captionLabelClassName,
            button_next: _buttonNextClassName,
            button_previous: _buttonPreviousClassName,
            nav: _navClassName,
            month_grid: _monthGridClassName,
            week: _weekClassName,
            day: _dayClassName,
            day_button: _dayButtonClassName,
            range_start: _rangeStartClassName,
            range_middle: _rangeMiddleClassName,
            range_end: _rangeEndClassName,
            selected: _selectedClassName,
            today: _todayClassName,
            outside: _outsideClassName,
            disabled: _disabledClassName,
            hidden: _hiddenClassName,
          }}
          components={{
            Chevron: ({ orientation }) => {
              return (
                <FontAwesomeIcon
                  icon={orientation === "left" ? faChevronLeft : faChevronRight}
                  className="h-4 w-4 text-gray-600"
                />
              );
            },
            Nav: ({ className }) => {
              const { nextMonth, previousMonth, goToMonth } = useDayPicker();

              const isPreviousDisabled = (() => {
                if (navView === "years") {
                  return (
                    (startMonth &&
                      differenceInCalendarDays(
                        new Date(displayYears.from - 1, 0, 1),
                        startMonth,
                      ) < 0) ||
                    (endMonth &&
                      differenceInCalendarDays(
                        new Date(displayYears.from - 1, 0, 1),
                        endMonth,
                      ) > 0)
                  );
                }
                return !previousMonth;
              })();

              const isNextDisabled = (() => {
                if (navView === "years") {
                  return (
                    (startMonth &&
                      differenceInCalendarDays(
                        new Date(displayYears.to + 1, 0, 1),
                        startMonth,
                      ) < 0) ||
                    (endMonth &&
                      differenceInCalendarDays(
                        new Date(displayYears.to + 1, 0, 1),
                        endMonth,
                      ) > 0)
                  );
                }
                return !nextMonth;
              })();

              const handlePreviousClick = () => {
                if (!previousMonth) return;

                if (navView === "years") {
                  setDisplayYears((prev) => ({
                    from: prev.from - (prev.to - prev.from + 1),
                    to: prev.to - (prev.to - prev.from + 1),
                  }));
                  onPrevClick?.(
                    new Date(
                      displayYears.from - (displayYears.to - displayYears.from),
                      0,
                      1,
                    ),
                  );
                  return;
                }

                goToMonth(previousMonth);

                onPrevClick?.(previousMonth);
              };

              const handleNextClick = () => {
                if (!nextMonth) return;

                if (navView === "years") {
                  setDisplayYears((prev) => ({
                    from: prev.from + (prev.to - prev.from + 1),
                    to: prev.to + (prev.to - prev.from + 1),
                  }));
                  onNextClick?.(
                    new Date(
                      displayYears.from + (displayYears.to - displayYears.from),
                      0,
                      1,
                    ),
                  );
                  return;
                }
                goToMonth(nextMonth);
                onNextClick?.(nextMonth);
              };

              return (
                <nav className={cn("flex items-center", className)}>
                  <Button
                    className="absolute left-12 h-7 w-7 bg-transparent p-0 opacity-80 hover:opacity-100"
                    disabled={isPreviousDisabled}
                    onClick={handlePreviousClick}
                  >
                    <FontAwesomeIcon
                      icon={faChevronLeft}
                      className="h-4 w-4 text-gray-600"
                    />
                  </Button>

                  <Button
                    className="absolute right-12 h-7 w-7 bg-transparent p-0 opacity-80 hover:opacity-100"
                    disabled={isNextDisabled}
                    onClick={handleNextClick}
                  >
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className="h-4 w-4 text-gray-600"
                    />
                  </Button>
                </nav>
              );
            },
            CaptionLabel: ({ children, ...props }) => {
              if (!showYearSwitcher) return <span {...props}>{children}</span>;
              return (
                <Button
                  className="pointer-events-auto absolute top-0 h-4 truncate text-sm font-medium"
                  variant="ghost"
                  onClick={() => {
                    setShowTimePicker(false);

                    setNavView((prev) => (prev === "days" ? "years" : "days"));
                  }}
                >
                  {navView === "days"
                    ? children
                    : displayYears.from + " - " + displayYears.to}
                </Button>
              );
            },
            MonthGrid: ({ className, children, ...props }) => {
              const { goToMonth, selected } = useDayPicker();

              if (navView === "years") {
                return (
                  <div
                    className={cn("grid grid-cols-4 gap-y-2", className)}
                    {...props}
                  >
                    {Array.from(
                      { length: displayYears.to - displayYears.from + 1 },
                      (_, i) => {
                        const isBefore =
                          startMonth &&
                          differenceInCalendarDays(
                            new Date(displayYears.from + i, 11, 31),
                            startMonth,
                          ) < 0;
                        const isAfter =
                          endMonth &&
                          differenceInCalendarDays(
                            new Date(displayYears.from + i, 0, 0),
                            endMonth,
                          ) > 0;
                        const isDisabled = isBefore || isAfter;

                        return (
                          <Button
                            key={i}
                            className={cn(
                              "h-7 w-full text-sm font-normal",
                              displayYears.from + i ===
                                new Date().getFullYear() &&
                                "bg-accent text-accent-foreground font-medium",
                            )}
                            variant="ghost"
                            onClick={() => {
                              setNavView("days");
                              goToMonth(
                                new Date(
                                  displayYears.from + i,
                                  (selected as Date | undefined)?.getMonth() ??
                                    0,
                                ),
                              );
                            }}
                            disabled={isDisabled}
                          >
                            {displayYears.from + i}
                          </Button>
                        );
                      },
                    )}
                  </div>
                );
              }

              return (
                <table className={className} {...props}>
                  {children}
                </table>
              );
            },
          }}
          numberOfMonths={columnsDisplayed}
          onDayClick={handleDayClick}
          {...props}
        />
        {showTimePicker && (
          <TimePicker selectedDate={selectedDate} className={className} />
        )}
      </div>
      {showAvailability && (
        <Button
          onClick={() => onTimeSelect?.(selectedStartTime!, selectedEndTime!)}
          disabled={!selectedStartTime || !selectedEndTime || !onTimeSelect}
          className="w-80"
        >
          <Small>Confirm Site Walk</Small>
        </Button>
      )}
    </div>
  );
}

Calendar.displayName = "Calendar";

export { Calendar };
