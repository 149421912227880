import * as Icons from "../icons/";

export const faIconsSet = new Set([
  "arrow-left",
  "arrow-right",
  "bars",
  "book",
  "calculator",
  "calendar-xmark",
  "check",
  "chevron-circle-left",
  "chevron-circle-right",
  "circle",
  "circle-check",
  "circle-user",
  "circle-question",
  "circle-info",
  "circle-xmark",
  "exclamation-triangle",
  "file-upload",
  "graduation-cap",
  "handshake",
  "heart",
  "hourglass",
  "inbox",
  "instagram",
  "lightbulb",
  "newspaper",
  "pallet",
  "pencil",
  "phone",
  "question",
  "right-from-bracket",
  "search",
  "seedling",
  "shopping-bag",
  "shovel",
  "sparkles",
  "star",
  "stars",
  "times",
  "trash-alt",
  "user",
  "xmark",
]);

export const svgIcons = {
  account: Icons.AccountIcon,
  "admin-read-convos": Icons.AdminReadConvosIcon,
  avatar: Icons.AvatarIcon,
  backyard: Icons.BackyardIcon,
  bell: Icons.BellIcon,
  blueprint: Icons.BlueprintIcon,
  bonded: Icons.BondedIcon,
  "build-project": Icons.BuildProjectIcon,
  calendar: Icons.CalendarIcon,
  chat2: Icons.Chat2Icon,
  "chat-bubble": Icons.ChatBubble,
  chat: Icons.ChatIcon,
  "chat-outline": Icons.ChatOutlineIcon,
  check1: Icons.Check1Icon,
  checklist: Icons.ChecklistIcon,
  "circled-check": Icons.CircledCheckIcon,
  constructor: Icons.ConstructorIcon,
  conversation: Icons.ConversationIcon,
  copy: Icons.CopyIcon,
  "design-projects": Icons.DesignProjectsIcon,
  "eco-friendly": Icons.EcoFriendlyIcon,
  "estimate-match": Icons.EstimateMatchIcon,
  estimates: Icons.EstimatesIcon,
  "family-owned": Icons.FamilyOwnedIcon,
  "female-founded": Icons.FemaleFoundedIcon,
  filters: Icons.FiltersIcon,
  "free-estimates": Icons.FreeEstimatesIcon,
  "front-yard": Icons.FrontYardIcon,
  "full-yard": Icons.FullYardIcon,
  goodbye: Icons.GoodbyeIcon,
  "hand-shake": Icons.HandShakeIcon,
  helmet: Icons.HelmetIcon,
  "helmet-outline": Icons.HelmetOutlineIcon,
  "help-center": Icons.HelpCenterIcon,
  help: Icons.HelpIcon,
  "house-and-bushes": Icons.HouseAndBushes,
  licensed: Icons.LicensedIcon,
  "loading-arrows": Icons.LoadingArrows,
  "location-marker": Icons.LocationMarkerIcon,
  login: Icons.LoginIcon,
  logout: Icons.LogoutIcon,
  "measuement-tape": Icons.MeasuementTapeIcon,
  "no-image-available": Icons.NoImageAvailable,
  "owner-operator": Icons.OwnerOperatorIcon,
  phone: Icons.PhoneIcon,
  plan: Icons.PlanIcon,
  plant: Icons.PlantIcon,
  plus: Icons.PlusIcon,
  "premium-experience": Icons.PremiumExperienceIcon,
  "pro-promise-review": Icons.ProPromiseReviewIcon,
  "pro-promise-service": Icons.ProPromiseServiceIcon,
  "pro-promise-verify": Icons.ProPromiseVerifyIcon,
  pros: Icons.ProsIcon,
  "responsive-communication": Icons.ResponsiveCommunicationIcon,
  "super-pro": Icons.SuperProIcon,
  tap: Icons.TapIcon,
  "text-instruction-one": Icons.TextInstructionOneIcon,
  "text-instruction-three": Icons.TextInstructionThreeIcon,
  "text-instruction-two": Icons.TextInstructionTwoIcon,
  "traditional-2d-plans": Icons.Traditional2DPlansIcon,
  "traditional-confused": Icons.TraditionalConfusedIcon,
  "traditional-locked-in": Icons.TraditionalLockedInIcon,
  "traditional-time-consuming": Icons.TraditionalTimeConsumingIcon,
  "value-pro": Icons.ValueProIcon,
  "veteran-owned": Icons.VeteranOwnedIcon,
  "view-estimate": Icons.ViewEstimateIcon,
  "white-chat": Icons.WhiteChatIcon,
  yardzen: Icons.YardzenIcon,
  "yardzen-approved": Icons.YardzenApprovedIcon,
  "yardzen-contractor": Icons.YardzenContractorIcon,
  "yardzen-design-talent": Icons.YardzenDesignTalentIcon,
  "yardzen-relaxed": Icons.YardzenRelaxedIcon,
  "yardzen-renders": Icons.YardzenRendersIcon,
  "years-in-business": Icons.YearsInBusinessIcon,

  // Project Elements
  adu: Icons.ProjectElementsIcons.aduIcon,
  "bbq-area": Icons.ProjectElementsIcons.bbqAreaIcon,
  deck: Icons.ProjectElementsIcons.deckIcon,
  "doors-replacement": Icons.ProjectElementsIcons.doorsReplacementIcon,
  driveway: Icons.ProjectElementsIcons.drivewayIcon,
  fences: Icons.ProjectElementsIcons.fencingIcon,
  "fire-pit": Icons.ProjectElementsIcons.firePitIcon,
  fountain: Icons.ProjectElementsIcons.fountainIcon,
  gates: Icons.ProjectElementsIcons.gatesIcon,
  "hot-tub": Icons.ProjectElementsIcons.hotTubIcon,
  "lawn-installation": Icons.ProjectElementsIcons.lawnInstallationIcon,
  "lawn-removal": Icons.ProjectElementsIcons.lawnRemovalIcon,
  "outdoor-kitchen": Icons.ProjectElementsIcons.outdoorKitchenIcon,
  "outdoor-lighting": Icons.ProjectElementsIcons.outdoorLightingIcon,
  "outdoor-play-area": Icons.ProjectElementsIcons.outdoorPlayAreaIcon,
  "outdoor-shower": Icons.ProjectElementsIcons.outdoorShowerIcon,
  painting: Icons.ProjectElementsIcons.paintingIcon,
  pathways: Icons.ProjectElementsIcons.pathwaysIcon,
  patio: Icons.ProjectElementsIcons.patioIcon,
  pergola: Icons.ProjectElementsIcons.pergolaIcon,
  planting: Icons.ProjectElementsIcons.plantingIcon,
  pool: Icons.ProjectElementsIcons.poolIcon,
  "raised-beds-vegetable-garden":
    Icons.ProjectElementsIcons.raisedBedsVegetableGardenIcon,
  "retaining-wall": Icons.ProjectElementsIcons.retainingWallIcon,
  roofing: Icons.ProjectElementsIcons.roofingIcon,
  shed: Icons.ProjectElementsIcons.shedIcon,
  "siding-replacement": Icons.ProjectElementsIcons.sidingReplacementIcon,
  "tree-removal": Icons.ProjectElementsIcons.treeRemovalIcon,
  trees: Icons.ProjectElementsIcons.treesIcon,
  "turf-installation": Icons.ProjectElementsIcons.turfInstallationIcon,
  "windows-replacement": Icons.ProjectElementsIcons.windowsReplacementIcon,
  // End Project Elements

  // Marketing Page Icons
  "anything-else-1":
    Icons.MarketingIcons.LandscapeSolutionsAnythingElseIcon.default,
  "anything-else-2": Icons.MarketingIcons.LivingAreasAnythingElseIcon.default,
  "back-yard": Icons.MarketingIcons.ClassicPlansBackYardIcon.default,
  botanical: Icons.MarketingIcons.MarketingBotanical.default,
  "cold-plunge": Icons.MarketingIcons.LivingAreasColdPlungeIcon.default,
  "concierge-support": Icons.MarketingIcons.MarketingConciergeSupport.default,
  connect: Icons.MarketingIcons.MarketingConnect.default,
  "contractor-match":
    Icons.MarketingIcons.ClassicPlansContractorMatchIcon.default,
  "design-director": Icons.MarketingIcons.MarketingDesignDirector.default,
  dining: Icons.MarketingIcons.LivingAreasDining.default,
  "door-selection": Icons.MarketingIcons.MarketingDoorSelection.default,
  drainage: Icons.MarketingIcons.LandscapeSolutionsDrainageIcon.default,
  "edible-garden": Icons.MarketingIcons.LivingAreasEdibleGarden.default,
  "extra-revisions": Icons.MarketingIcons.MarketingExtraRevisions.default,
  "fast-delivery": Icons.MarketingIcons.MarketingFastDelivery.default,
  "furniture-and-decor":
    Icons.MarketingIcons.ClassicPlansFurnitureAndDecorIcon.default,
  "hardscaping-design":
    Icons.MarketingIcons.ClassicPlansHardscapingDesignIcon.default,
  "home-vision": Icons.MarketingIcons.MarketingHomeVision.default,
  "house-painting": Icons.MarketingIcons.MarketingHousePainting.default,
  ideas: Icons.MarketingIcons.MarketingIdeas.default,
  kids: Icons.MarketingIcons.LandscapeSolutionsKidsIcon.default,
  kitchen: Icons.MarketingIcons.LivingAreasKitchenIcon.default,
  "landscape-design":
    Icons.MarketingIcons.ClassicPlansLandscapeDesignIcon.default,
  "lighting-design":
    Icons.MarketingIcons.ClassicPlansLightingDesignIcon.default,
  lounge: Icons.MarketingIcons.LivingAreasLoungeIcon.default,
  "marketing-deck": Icons.MarketingIcons.LivingAreasDeckIcon.default,
  "marketing-firepit": Icons.MarketingIcons.LivingAreasFirepitIcon.default,
  "marketing-front-yard":
    Icons.MarketingIcons.ClassicPlansFrontYardIcon.default,
  "marketing-full-yard": Icons.MarketingIcons.ClassicPlansFullYardIcon.default,
  "marketing-patio": Icons.MarketingIcons.LivingAreasPatioIcon.default,
  "marketing-retaining-wall":
    Icons.MarketingIcons.LandscapeSolutionsRetainingWallIcon.default,
  "native-plants":
    Icons.MarketingIcons.LandscapeSolutionsNativePlantsIcon.default,
  noise: Icons.MarketingIcons.LandscapeSolutionsNoiseIcon.default,
  "one-designer-call":
    Icons.MarketingIcons.ClassicPlansOneDesignerCallIcon.default,
  "one-round-of-revisions":
    Icons.MarketingIcons.ClassicPlansOneRoundOfRevisionsIcon.default,
  "paint-color-selection":
    Icons.MarketingIcons.MarketingPaintColorSelection.default,
  pets: Icons.MarketingIcons.LandscapeSolutionsPetsIcon.default,
  "play-space": Icons.MarketingIcons.LivingAreasPlaySpaceIcon.default,
  privacy: Icons.MarketingIcons.LandscapeSolutionsPrivacyIcon.default,
  sauna: Icons.MarketingIcons.LivingAreasSaunaIcon.default,
  "seamless-process":
    Icons.MarketingIcons.ClassicPlansSeamlessProcessIcon.default,
  "seasonal-use":
    Icons.MarketingIcons.LandscapeSolutionsSeasonalUseIcon.default,
  shade: Icons.MarketingIcons.LandscapeSolutionsShadeIcon.default,
  "side-yard-design": Icons.MarketingIcons.MarketingSideYardDesign.default,
  slope: Icons.MarketingIcons.LandscapeSolutionsSlopeIcon.default,
  "swimming-pool": Icons.MarketingIcons.LivingAreasSwimmingPoolIcon.default,
  "water-reduction":
    Icons.MarketingIcons.LandscapeSolutionsWaterReductionIcon.default,
  "window-design": Icons.MarketingIcons.MarketingWindowDesign.default,
  "yard-functional": Icons.MarketingIcons.MarketingYardFunctional.default,
  // End Marketing Page Icons

  // Design Quiz Icons
  "already-hired-builder": Icons.DesignQuizIcons.AlreadyHiredBuilderIcon,
  "backyard-house": Icons.DesignQuizIcons.BackyardHouseIcon,
  "beautiful-space": Icons.DesignQuizIcons.BeautifulSpaceIcon,
  cactus: Icons.DesignQuizIcons.CactusIcon,
  "calendar-alert": Icons.DesignQuizIcons.CalendarAlertIcon,
  "challenge-yard": Icons.DesignQuizIcons.ChallengeYardIcon,
  "design-quiz-cold-plunge": Icons.DesignQuizIcons.ColdPlungeIcon,
  "design-quiz-deck": Icons.DesignQuizIcons.DeckIcon,
  "design-quiz-dining": Icons.DesignQuizIcons.DiningIcon,
  "design-quiz-dream-deck": Icons.DesignQuizIcons.DreamDeckIcon,
  "design-quiz-dreaming": Icons.DesignQuizIcons.DreamingIcon,
  "exterior-redesign": Icons.DesignQuizIcons.ExteriorRedesignIcon,
  "exterior-surface": Icons.DesignQuizIcons.ExteriorSurfaceIcon,
  fix: Icons.DesignQuizIcons.FixIcon,
  "fix-hardscape": Icons.DesignQuizIcons.FixHardscapeIcon,
  "front-yard-house": Icons.DesignQuizIcons.FrontYardHouseIcon,
  "functional-yard": Icons.DesignQuizIcons.FunctionalYardIcon,
  "garage-door": Icons.DesignQuizIcons.GarageDoorIcon,
  garden: Icons.DesignQuizIcons.GardenIcon,
  guests: Icons.DesignQuizIcons.GuestsIcon,
  hardscaping: Icons.DesignQuizIcons.HardscapingIcon,
  hardware: Icons.DesignQuizIcons.HardwareIcon,
  "heritage-trees": Icons.DesignQuizIcons.HeritageTreesIcon,
  "design-quiz-hot-tub": Icons.DesignQuizIcons.HotTubIcon,
  "house-paint": Icons.DesignQuizIcons.HousePaintIcon,
  irrigation: Icons.DesignQuizIcons.IrrigationIcon,
  "design-quiz-kids": Icons.DesignQuizIcons.KidsIcon,
  "kinda-sloped": Icons.DesignQuizIcons.KindaSlopedIcon,
  "design-quiz-kitchen": Icons.DesignQuizIcons.KitchenIcon,
  "lacks-privacy": Icons.DesignQuizIcons.LacksPrivacyIcon,
  landscaping: Icons.DesignQuizIcons.LandscapingIcon,
  "life-into-date-yard": Icons.DesignQuizIcons.LifeIntoDateYardIcon,
  "light-challenge": Icons.DesignQuizIcons.LightChallengeIcon,
  lighting: Icons.DesignQuizIcons.LightingIcon,
  "little-slope": Icons.DesignQuizIcons.LittleSlopeIcon,
  "lot-of-trees": Icons.DesignQuizIcons.LotOfTreesIcon,
  myself: Icons.DesignQuizIcons.MyselfIcon,
  "new-space-yard": Icons.DesignQuizIcons.NewSpaceYardIcon,
  no: Icons.DesignQuizIcons.NoIcon,
  "design-quiz-patio": Icons.DesignQuizIcons.PatioIcon,
  "pet-dream-yard": Icons.DesignQuizIcons.PetDreamYardIcon,
  "pets-focus": Icons.DesignQuizIcons.PetsFocusIcon,
  "pets-no-focus": Icons.DesignQuizIcons.PetsNoFocusIcon,
  "pets-some-focus": Icons.DesignQuizIcons.PetsSomeFocusIcon,
  "design-quiz-pets": Icons.DesignQuizIcons.PetsIcon,
  "plain-terrain": Icons.DesignQuizIcons.PlainTerrainIcon,
  plants: Icons.DesignQuizIcons.PlantsIcon,
  playground: Icons.DesignQuizIcons.PlaygroundIcon,
  "design-quiz-privacy": Icons.DesignQuizIcons.PrivacyIcon,
  "ready-now": Icons.DesignQuizIcons.ReadyNowIcon,
  "ready-soon": Icons.DesignQuizIcons.ReadySoonIcon,
  "retaining-walls": Icons.DesignQuizIcons.RetainingWallsIcon,
  revive: Icons.DesignQuizIcons.ReviveIcon,
  "road-noise": Icons.DesignQuizIcons.RoadNoiseIcon,
  "design-quiz-sauna": Icons.DesignQuizIcons.SaunaIcon,
  "seasonal-interest": Icons.DesignQuizIcons.SeasonalInterestIcon,
  "side-yard": Icons.DesignQuizIcons.SideYardIcon,
  sloped: Icons.DesignQuizIcons.SlopedIcon,
  spring: Icons.DesignQuizIcons.SpringIcon,
  style: Icons.DesignQuizIcons.StyleIcon,
  "design-quiz-swimming-pool": Icons.DesignQuizIcons.SwimmingPoolIcon,
  "very-sloped": Icons.DesignQuizIcons.VerySlopedIcon,
  "water-consumption": Icons.DesignQuizIcons.WaterConsumptionIcon,
  "whole-yard": Icons.DesignQuizIcons.WholeYardIcon,
  "with-trees": Icons.DesignQuizIcons.WithTreesIcon,
  "yard-maintenance": Icons.DesignQuizIcons.YardMaintenanceIcon,
  yes: Icons.DesignQuizIcons.YesIcon,
  "new-build": Icons.DesignQuizIcons.NewBuildIcon,
  "from-scratch": Icons.DesignQuizIcons.FromScratchIcon,
  "water-drainage": Icons.DesignQuizIcons.WaterDrainageIcon,
  "failing-infra": Icons.DesignQuizIcons.FailingInfraIcon,
  "slope-challenge": Icons.DesignQuizIcons.SlopeChallengeIcon,
  "something-else-challenge": Icons.DesignQuizIcons.SomethingElseChallengeIcon,
  entertaining: Icons.DesignQuizIcons.EntertainingIcon,
  playing: Icons.DesignQuizIcons.PlayingIcon,
  cooking: Icons.DesignQuizIcons.CookingIcon,
  relaxing: Icons.DesignQuizIcons.RelaxingIcon,
  wellness: Icons.DesignQuizIcons.WellnessIcon,
  "enjoy-view": Icons.DesignQuizIcons.EnjoyViewIcon,
  "design-quiz-calendar": Icons.DesignQuizIcons.CalendarIcon,
  yesterday: Icons.DesignQuizIcons.YesterdayIcon,
  "doors-windows": Icons.DesignQuizIcons.DoorWindowIcon,
  "location-pin": Icons.DesignQuizIcons.LocationIcon,
};

export const reactIcons = {
  "chevron-right": Icons.ArrowRight,
  calendar: Icons.Calendar,
  "chat-filled": Icons.ChatFilled,
  "circled-check": Icons.CircledCheck,
  "new-tab-link": Icons.NewTabLink,
  "paper-airplane": Icons.PaperAirplane,
  sweat: Icons.Sweat,
  "tooltip-info": Icons.TooltipInfo,
};

export const pngIcons = {
  "affirm-logo": Icons.AffirmLogoIcon,
  "yz-pro-header-logo": Icons.YzProHeaderLogoIcon,
};

export const svgIconsSet = new Set(Object.keys(svgIcons));
export const reactIconsSet = new Set(Object.keys(reactIcons));
export const pngIconsSet = new Set(Object.keys(pngIcons));
